@tailwind base;
@tailwind components;
@tailwind utilities;

@responsive {
  .text-shadow {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .text-shadow-md {
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.08);
  }

  .text-shadow-lg {
    text-shadow: 0 15px 30px rgba(0, 0, 0, 0.11), 0 5px 15px rgba(0, 0, 0, 0.08);
  }

  .text-shadow-none {
    text-shadow: none;
  }
}

/* manrope-200 - latin */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 200;
  src: local(""), url("./fonts/manrope-v4-latin-200.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/manrope-v4-latin-200.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* manrope-regular - latin */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("./fonts/manrope-v4-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/manrope-v4-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* manrope-300 - latin */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 300;
  src: local(""), url("./fonts/manrope-v4-latin-300.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/manrope-v4-latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* manrope-500 - latin */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  src: local(""), url("./fonts/manrope-v4-latin-500.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/manrope-v4-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* manrope-700 - latin */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("./fonts/manrope-v4-latin-700.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/manrope-v4-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* manrope-600 - latin */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  src: local(""), url("./fonts/manrope-v4-latin-600.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/manrope-v4-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* manrope-800 - latin */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 800;
  src: local(""), url("./fonts/manrope-v4-latin-800.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("./fonts/manrope-v4-latin-800.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@import "~react-image-gallery/styles/css/image-gallery.css";

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a,
ul,
li,
button,
textarea,
input,
select,
ol,
div {
  font-family: "Manrope";
  text-rendering: optimizeLegibility;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: -0.03em;
  color: #18191f;
}
h1 > span,
h2 > span,
h3 > span,
h4 > span,
h5 > span,
h6 > span {
  color: #fdc228;
}
p,
strong {
  color: #18191f;
}

h1 {
  font-size: 72px;
  font-weight: 800;
}
h2 {
  font-size: 48px;
  font-weight: 800;
}
h3 {
  font-size: 40px;
  font-weight: 800;
}
h4 {
  font-size: 28px;
  font-weight: 800;
}
h5 {
  font-size: 24px;
  font-weight: 600;
}
h6 {
  font-size: 20px;
  font-weight: 500;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Manrope";
}

a {
  color: inherit;
  text-decoration: none;
  color: #0066cc;
}

* {
  box-sizing: border-box;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body,
input,
textarea,
select,
button {
  font-synthesis: none;
  -moz-font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  direction: ltr;
  text-align: left;
}

.sme-no-scroll {
  overflow: hidden !important;
  height: 100% !important;
}
hr {
  width: 100%;
  border-top: 1px solid #9f9f9f;
  border-left: none;
  border-right: none;
  border-bottom: none;
}
